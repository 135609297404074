import './styles/Common.scss';
import './styles/App.scss';
import React, { useEffect } from 'react';
import {
  BrowserRouter, MemoryRouter as Router, Route, Routes,
} from 'react-router-dom';
import TechPostPage from './pages/TechPostPage';
import Layout from './components/Layout';
import SettingPage from './pages/SettingPage';
import HomePage from './pages/HomePage';
import LectureListPage from './pages/LectureListPage';
import PostDetailPage from './pages/PostDetailPage';
import PostForm from './components/PostForm';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import FileUploadPage from './pages/FileUploadPage';
import SurveyPage from './pages/SurveyPage';
import SurveyResultPage from './pages/SurveyResultPage';
import MainPage from './pages/MainPage';

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/lectureList" element={<LectureListPage />} />
          <Route path="/postDetail/:id" element={<PostDetailPage />} />
          <Route path="/postForm" element={<PostForm handleForm={undefined} modalClose={undefined} />} />
          <Route path="/techPost/:id" element={<TechPostPage />} />
          <Route path="/setting" element={<SettingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/fileUpload" element={<FileUploadPage />} />
          <Route path="/survey" element={<SurveyPage />} />
          <Route path="/surveyResult" element={<SurveyResultPage />} />
          <Route path="/main" element={<MainPage />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
