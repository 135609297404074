import React, { useEffect, useMemo, useState } from 'react';
import '../styles/pages/SurveyResultPage.scss';
import Api from '../api/Api';
import useSWR from 'swr';
import { VideoType } from '../../@types/video';
import { getFetcher } from '../fetcher/fetcher';
import { useMediaQuery } from '../hooks/useMediaQuery';

const SurveyResultPage = () => {
  const [surveyResultState, setSurveyResultState] = useState([]);
  const [surveyCnt, setSurveyCnt] = useState(0);
  const { data: swrVideosData, error: swrVideosDataErr } = useSWR<Array<VideoType>>('/api/video', getFetcher, { refreshInterval: 3000 });
  const isPageWide = useMediaQuery('(min-width: 916px)');
  const handleMenuHide = () => {
    if (!isPageWide) {
      const menuBar = (document.querySelector('.sideNaviBox') as HTMLDivElement);
      menuBar.style.display = 'none';
    }
  };
  useEffect(() => {
    swrVideosData && console.log('swrVideosData', swrVideosData[0].title);
    swrVideosData && console.log('swrVideosData', swrVideosData[0].viewCount);
  }, [swrVideosData]);

  /* INIT EFFECT */
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    Api.surveyResult.getAllDatas().then((surRes) => {
      setSurveyCnt(surRes.length);
      const surveyResults = surRes.map((res) => res.result);
      const surQuestions = [];
      surveyResults.forEach((obj) => surQuestions.push(...Object.keys(obj)));
      const surQuestionKeys = [...new Set(surQuestions)];
      const newg = (key) => ({ [`${key}`]: [] });
      let hello = {};
      surQuestionKeys.forEach((key) => {
        hello = { ...hello, ...(newg(key)) };
        surveyResults.forEach((obj) => {
          if (typeof obj[key] === 'object')hello[key].push(...obj[key]);
          else hello[key].push(obj[key]);
        });
      });
      const finalSurveyResult = [];
      surQuestionKeys.forEach((key) => {
        const result = hello[key].reduce((accu, curr) => {
          accu[curr] = (accu[curr] || 0) + 1; // 객체에서 curr key값을 찾아 value값이 있으면 그 value에서 1을 더하고, 없다면 0을 할당하고 거기에 1을 더해준다.
          return accu;
        }, {});
        finalSurveyResult.push({ [key]: result });
      });
      setSurveyResultState(finalSurveyResult);
    });
  }, []);

  const swrVideosViewCheckMap = useMemo(() => swrVideosData?.map(
    (data, idx) => (
      <div className="viewResultBox" key={idx}>
        <h3>{data.title}</h3>
        <span>
          👀
          <h2>{data.viewCount}</h2>
          번 조회 되었습니다.
        </span>
      </div>
    ),
  ), [swrVideosData]);

  const surveyResultMap = useMemo(() => surveyResultState.map((surRes, idx) => {
    const title = Object.keys(surRes)[0];
    const surveyResultObj = surRes[title];
    const surveyAnswer = Object.entries(surveyResultObj);
    const surveyAnswerMap = surveyAnswer.map((answer:Array<any>, answeridx) => (
      <>
        <div className="txt" key={answeridx}>
          <span>{`🗣답변 : ${answer[0]}`}</span>
          <span className="verticalBar">    ┃    </span>
          <span>{title !== '기타' && `👍응답 갯수 : ${answer[1]}`}</span>
        </div>
        <div className="subCon">
          {/* <span> */}
          {/*  {surveyCnt} */}
          {/*  명이 응답하였습니다. */}
          {/* </span> */}
          {/* <span>2022-02</span> */}
        </div>
      </>
    ));
    return (
      <section className="content" key={idx}>
        <div className="txtCon">
          <h3>{`${title}`}</h3>
          {surveyAnswerMap}
        </div>
        <div className="answerCon">
          {/* 댓글 length로 바꾸기 */}
          <div className="circle">
            <span>{surveyAnswer.length}</span>
            <span>개의 답변</span>
          </div>
        </div>
      </section>
    );
  }), [surveyResultState]);

  return (
    <div className="surveyResultContainer" onScroll={handleMenuHide}>
      <h2>조회수 결과</h2>
      <section className="viewResultSection">{swrVideosViewCheckMap}</section>

      <h2>설문 결과</h2>
      {/* <h2>{`총 ${surveyCnt}명이 응답하였습니다.`}</h2> */}
      <h2 style={{ display: 'flex' }}>
        총
        <h1 style={{ marginTop: '-16px', marginLeft: ' 5px', marginRight: '5px' }}>{surveyCnt}</h1>
        명이 응답하였습니다.
      </h2>
      <div className="techBoardContainer">
        <div className="contentBox">
          {surveyResultMap}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default SurveyResultPage;
