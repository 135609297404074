import React, { FormEvent, useState } from 'react';
import '../styles/pages/SignupPage.scss';
import LogoImg from '../icons/logo.png';
import ChangePwdPopup from '../components/ChangePwdPopup';
// import BackBtn from '~/src/components/modules/BackBtn';

interface UserStates {
  email: string
  password: string
}

const SignupPage = () => {
  const [pwdState, setPwdState] = useState({
    valid: true,
    class: 'form',
    captionClass: 'caption',
  });
  const [matchState, setMatchState] = useState({
    valid: true,
    class: 'form',
    captionClass: 'caption',
  });
  const [emailState, setEmailState] = useState({
    valid: true,
    class: 'form',
    captionClass: 'caption',
  });
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [user, setUser] = useState<UserStates>({
    email: null,
    password: null,
  });

  const checkPwd = (e: FormEvent<HTMLInputElement>) => {
    const pwd = e.currentTarget.value;
    const re = /.*[^a-zA-Z0-9]+.*/gm;
    // var chk_kr = pwd.search(/[가-힣]/ig);
    const check = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{6,}$/;

    // 정규 표현식을 통과하지 못하면
    if (!check.test(pwd)) {
      // 처리할 문장
      setPwdState({
        valid: false,
        class: 'form wrong',
        captionClass: 'caption wrong',
      });
    } else {
      setPwdState({
        valid: true,
        class: 'form',
        captionClass: 'caption',
      });
    }
  };

  const checkPwdMatch = (m: FormEvent<HTMLInputElement>) => {
    const pwd = document.getElementById('password') as HTMLInputElement;
    const match = m.currentTarget.value;
    if (pwd.value !== match && match.length !== 0) setMatchState({ valid: false, class: 'form wrong', captionClass: 'caption wrong' });
    else setMatchState({ valid: true, class: 'form', captionClass: 'caption' });
  };

  const checkEmail = (e: FormEvent<HTMLInputElement>) => {
    const email = e.currentTarget.value;
    // const re = /^.+@.+\..+$/gm
    const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
    clearTimeout(timer);

    if (!email.includes('@') && email.length > 64) {
      const emailEle = document.getElementById('email') as HTMLInputElement;
      emailEle.value = emailEle.value.substr(0, emailEle.value.length - 1);
      return;
    }

    if (email.length !== 0) {
      if (!re.test(email)) {
        // if email is not valid form
        setEmailState({ valid: false, class: 'form wrong', captionClass: 'caption wrong' });
        document.getElementsByClassName('caption')[0].textContent = '이메일 형식이 맞지 않습니다.';
      } else {
        // if valid, check email duplication
        setEmailState({ valid: true, class: 'form', captionClass: 'caption' });
        // cl('email', email);
        setTimer(
          setTimeout(async () => {
            // const response = await hnbgAxios.post('/graphql', {
            //   query: `
            //     {
            //       users(where: {
            //         email:"${email}"
            //       }){
            //       email
            //       }
            //     }
            //   `,
            // });
            // cl('response', response);
            // cl('response.data.data', response.data.data);
            // const isDupe = response.data.data.users.length !== 0;
            // const emailCaption = document.getElementsByClassName('caption')[0];
            // if (isDupe) {
            //   setEmailState({ valid: false, class: 'form wrong', captionClass: 'caption wrong' });
            //   emailCaption.textContent = t('SignupPage.checkEmailDup');
            // }
          }, 500),
        );
      }
      // eslint-disable-next-line brace-style
    }
    // if empty
    else setEmailState({ valid: true, class: 'form', captionClass: 'caption' });
  };

  const sanitize = (e: FormEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const { value } = target;
    let re: RegExp;

    if (target.classList.contains('point')) {
      re = /^[0-9.]+$/gm;
    } else re = /^[0-9]+$/gm;

    if (!re.test(value[value.length - 1])) {
      target.value = value.slice(0, value.length - 1);
    }
  };

  // 입력 처리
  const handleInput = (e: FormEvent<HTMLInputElement>) => {
    const { id } = e.currentTarget;
    const value = Number(e.currentTarget.value);
    const eVal = e.currentTarget.value;
    const targets = document.querySelectorAll('input');
    // 0: input#name.form
    // 1: input#email.form.wrong
    // 2: input#password.form
    // 3: input.pwShowCheck
    // 4: input#passwordChk.form
    // 5: input.pwShowCheck
    // 6: input#cellphone.form
    // 7: input#birthday.form
    // 8: input#gender
    // 9: input#genderF
    // 10: input#height.form.point
    // 11: input#weight.form.point

    setUser((prevState) => ({ ...prevState, [id]: value }));
  };

  /* 기존 비밀번호 보기 */
  const curPwdShow = () => {
    const x3 = document.getElementById('password') as HTMLInputElement;
    if (x3.type === 'password') x3.type = 'text';
    else x3.type = 'password';
  };

  /* 비밀번호 보기 */
  const pwdShow = () => {
    const x1 = document.getElementById('passwordChk') as HTMLInputElement;
    if (x1.type === 'password') x1.type = 'text';
    else x1.type = 'password';
  };

  const submit = () => {
    const regExp = /^[가-힣a-zA-Z]+$/;
    // const nameCheck = (document.getElementById('name') as HTMLInputElement).value;
    // if (!regExp.test(nameCheck)) {
    //   alert('성명에 문자만 입력해주세요.');
    //   return;
    // }

    // check required input
    let fulfilled = true;
    document.querySelectorAll('input:required').forEach((e: HTMLInputElement) => {
      const checked = e.value;
      fulfilled = Boolean(fulfilled && checked);
    });

    // check email, pwd validity
    let valid = true;
    valid = Boolean(emailState.valid && pwdState.valid && matchState.valid);

    // register user
    if (fulfilled && valid) {
      Object.keys(user).forEach((key) => {
        let q = '';
        if (key === 'gender') q = 'input[type="radio"]:checked';
        else q = `#${key}`;

        const target = document.querySelector(q) as HTMLInputElement;
        user[key] = target ? target.value : null;
      });
    } else {
      /* 필수항목을 모두 입력해주세요. */
      // eslint-disable-next-line no-lonely-if
      if (!fulfilled) alert('필수항목을 모두 입력해주세요.');
      /* 형식에 맞게 입력해주세요 */
      else window.alert('형식에 맞게 입력해주세요.');
    }
  };

  // if (signInLoading) return <Loading />;
  // if (signIn) return <Loading />;
  return (

    <div className="signupContainer">
      <div className="loginBox">
        <div className="left">
          <div className="loginBg" />
          <div className="logoImg">
            <img src={LogoImg} alt="" />
            관리자 로그인
          </div>
        </div>
        <div className="right">
          <div className="top">
            <span className="title">SIGN IN</span>
            <div className="inputLoginBox">
              <div className="content">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>email</label>
                <input
                  id="email"
                  className={emailState.class}
                  placeholder="아이디(이메일)"
                  onInput={checkEmail}
                  maxLength={320}
                  required
                />
                <span className={emailState.captionClass} hidden={emailState.valid}>
                  이메일 형식이 맞지 않습니다.
                </span>
              </div>
              <div className="content">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>Password</label>
                <input
                  id="password"
                  className={pwdState.class}
                  placeholder="비밀번호"
                  type="password"
                  maxLength={16}
                  onInput={checkPwd}
                  required
                />
                <span className={pwdState.captionClass}>
                  6-16자의 영문/숫자/특수문자 조합만 가능합니다.
                </span>
              </div>
              <div className="pwShowCheckBox">
                <input className="pwShowCheck" type="checkbox" onClick={curPwdShow} />
                비밀번호 보기
              </div>
              <div className="content">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>Password</label>
                <input
                  id="passwordChk"
                  className={matchState.class}
                  placeholder="비밀번호 확인"
                  type="password"
                  maxLength={16}
                  onInput={checkPwdMatch}
                  required
                />
                <span className={matchState.captionClass} hidden={matchState.valid}>
                  비밀번호가 일치하지 않습니다.
                </span>
              </div>
              <div className="pwShowCheckBox">
                <input className="pwShowCheck" type="checkbox" onClick={pwdShow} />
                비밀번호 보기
              </div>
            </div>
          </div>
          <div className="loginSaveBtn">
            <input type="checkbox" />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Save</label>
          </div>
          <div className="loginBtnBox">
            <button className="btnR defaultPrimary" onClick={submit}>등록</button>
          </div>
          {/* <div className="loginPwdBtnBox"> */}
          {/*  <button className="btnR defaultES" onClick={forgetPwdPopup}> */}
          {/*    FORGET PASSWORD */}
          {/*  </button> */}
          {/*  <button className="btnR defaultES" onClick={changePwdPopup}> */}
          {/*    CHANGE PASSWORD */}
          {/*  </button> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
