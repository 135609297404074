import React, { useEffect } from 'react';
import SideNavi from './SideNavi';
import '../styles/Layout.scss';
import { useSWRState } from '../fetcher/useSWRState';
import Api from '../api/Api';
import { useMediaQuery } from '../hooks/useMediaQuery';

const Layout = ({ children }) => {
  const { data: swrState, mutate: setSwrState } = useSWRState();
  const isPageWide = useMediaQuery('(min-width: 916px)');
  const handleMenuHide = () => {
    if (!isPageWide) {
      const menuBar = (document.querySelector('.sideNaviBox') as HTMLDivElement);
      menuBar.style.display = 'none';
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const isInitWatched = swrState?.isWatched === undefined;
    if (isInitWatched) {
      Api.video.getAllDatas().then((res) => {
        const resMap = res.map((obj) => obj._id);
        const obj = {};
        // eslint-disable-next-line no-return-assign
        resMap.forEach((id) => obj[id] = false);
        setSwrState({ ...swrState, isWatched: obj });
      });
    }

    window.addEventListener('scroll', () => {
      // 스크롤을 할 때마다 로그로 현재 스크롤의 위치가 찍혀나온다.
      console.log(window.scrollX, window.scrollY);
    });
  }, []);

  return (
    <div className="layoutContainer" onScroll={handleMenuHide}>
      <SideNavi />
      <div className="layoutRightContainer">
        {children}
      </div>
    </div>
  );
};

export default Layout;
