import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import dummy from '../db/data.json';
import '../styles/TechPost.scss';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import parse from 'html-react-parser';

const TechPostPage = () => {
  const navigation = useNavigate();

  const [movieContent, setMovieContent] = useState({
    content: '',
  });

  const [viewContent, setViewContent] = useState([]);

  const getValue = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    setMovieContent({
      ...movieContent,
      [name]: value,
      // movieContent 의 내용을 복사해서 그 안에 name이라는 이름의 키의 값을 value로 바꿔 저장
    });
    console.log(movieContent);
  };

  const ckEditor = (
    <CKEditor
      style={{ height: '100px' }}
      editor={ClassicEditor}
      placeholder="내용을 입력해 주세요"
      onChange={(event, editor) => {
        const data = editor.getData();
        console.log({ event, editor, data });
        setMovieContent({
          ...movieContent,
          content: data,
        });
        console.log(movieContent);
      }}
    />
  );

  return (
    <div className="techPostWrap">
      <span className="arrowIcon">
        <ArrowBackIosIcon onClick={() => {
          navigation(-1);
        }}
        />
      </span>
      <div className="techPostContainer">
        <div className="questionCon">
          <h3>{`${dummy.post[0].title.slice(0, 30)}...`}</h3>
          <div className="subCon">
            <span>{dummy.post[0].writer}</span>
            <span>{dummy.post[0].date}</span>
            <span>{dummy.post[0].category}</span>
          </div>
          <div className="txt">{dummy.post[0].text}</div>
        </div>
        <div className="bg">
          {/* <div className="answerCon"> */}
          {/*    <h3>{dummy.post[0].title.slice(0, 30) + '...'}</h3> */}
          {/*    <div className="subCon"> */}
          {/*        <span>{dummy.post[0].writer}</span> */}
          {/*        <span>{dummy.post[0].date}</span> */}
          {/*    </div> */}
          {/*    <div className="txt">{dummy.post[0].text}</div> */}
          {/* </div> */}
          <div className="answerCon">
            <h3>답변</h3>
            {/* <div className="subCon"> */}
            {/*    <span>{dummy.post[0].writer}</span> */}
            {/*    <span>{dummy.post[0].date}</span> */}
            {/*    <span>{dummy.post[0].category}</span> */}
            {/* </div> */}
            {viewContent.map((ele) => (
              <div className="answer">
                <div className="subCon">
                  <span>{dummy.post[0].writer}</span>
                  <span>{dummy.post[0].date}</span>
                  <span>{dummy.post[0].category}</span>
                </div>
                <div className="txt">{parse(ele.content)}</div>
              </div>
            ))}
          </div>
          <div className="formWrap">
            <div className="formBox">
              {ckEditor}
            </div>
            <button
              className="submit-button"
              onClick={() => {
                setViewContent(viewContent.concat({ ...movieContent }));
              }}
            >
              답변 등록
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechPostPage;
