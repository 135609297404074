import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Video from '../video/KakaoTalk_Video_2022-08-25-00-25-04.mp4';
import Api from '../api/Api';
import { getCurDateTime, getRandomId, renameFile } from '../utils/utilities';
import { SERVER_BASE_URL } from '../index';

/*
리액트에서 비디오 재생을 하고 싶다면 public에 비디오를 넣고 아래처럼 path를 지정해줘야 된다.
<source src="/videos/KakaoTalk_Video_2022-08-25-00-24-43.mp4" type="video/mp4"/>
* */
const FileUploadPage = () => {
  const [thumbnailState, setThumbnailState] = useState<File>();
  const [videoFileState, setVideoFileState] = useState<File>();
  const [videosFileState, setVideosFileState] = useState<FileList>();
  const [fileInfoState, setFileInfoState] = useState({
    title: '',
    category: '',
  });
  const fileNameFilter = (fileName) => {
    const splitedFileName = fileName.split('.');
    splitedFileName.pop();
    const newFileName = splitedFileName.join('');
    return newFileName;
  };
  const handleSetVideoFiles = (e) => {
    const fileName = e.target.files[0].name;
    setVideoFileState(renameFile(e.target.files[0], `${fileNameFilter(fileName)}-${getCurDateTime()}.mp4`));
  };
  const handleInputState = (e) => {
    const target = e.currentTarget;
    const { value } = target;
    const dType = target.getAttribute('datatype');
    setTimeout(() => setFileInfoState({ ...fileInfoState, [dType]: value }), 20);
  };
  const handleSetVideosFiles = (e) => setVideosFileState(e.target.files);
  const handleSetThumbnail = (e) => {
    const fileName = e.target.files[0].name;
    setThumbnailState(renameFile(e.target.files[0], `${fileNameFilter(fileName)}-${getCurDateTime()}.webp`));
  };
  useEffect(() => {
    if (thumbnailState) {
      console.log('thumbnailState', thumbnailState);
      console.log('thumbnailState', thumbnailState.name);
    }
  }, [thumbnailState]);

  /* multi videos upload */
  const uploadVideos = () => {
    const videoFormData = new FormData();
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const videos = Array.from(videosFileState);
    videos.forEach((file) => videoFormData.append('files', file));
    axios.post('/api/files/uploadVideos', videoFormData, config)
      .then((res) => {
        console.log('res', res);
      });
  };

  /* single video upload */
  const uploadVideo = () => {
    const videoFormData = new FormData();
    const config = { headers: { 'Content-Type': 'video/mp4' } };
    // videoFormData.append("file", renameFile(videoFileState, `${videoFileState.name}-${getCurDateTime()}`))
    videoFormData.append('file', videoFileState);
    axios.post('/api/files/uploadVideo', videoFormData, config)
      .then((res) => {
        console.log('res', res);
      });
  };

  /* single image upload */
  const uploadThumbnail = () => {
    const thumbnailFormData = new FormData();
    const config = { headers: { 'Content-Type': 'image/jpeg' } };
    thumbnailFormData.append('file', thumbnailState);
    console.log('thumbnailState', thumbnailState);
    console.log('thumbnailFormData', thumbnailFormData);
    axios.post('/api/files/uploadThumbnail', thumbnailFormData, config)
      .then((res) => {
        console.log('res', res);
      });
  };

  /* 이미지랑 비디오는 Backend에 저장할것. */
  const onDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    await uploadVideo();
    await uploadThumbnail();
    Api.video.saveData({
      surveyId: '',
      category: fileInfoState.category,
      title: fileInfoState.title,
      path: videoFileState.name,
      thumbnail: thumbnailState.name,
      viewCount: 0,
    }).then((res) => {
      console.log('res', res);
    }).catch((err) => console.error(err));
  };

  useEffect(() => {
    console.log('getCurDateTime()', getCurDateTime());
    const testInterval = setInterval(() => {
      console.log('끝남?', (document.querySelector('#comwelVideo') as HTMLVideoElement).ended);
    }, 500);
    return () => {
      clearTimeout(testInterval);
    };
  }, []);

  return (
    <div>
      <div>
        <div>썸네일 업로드</div>
        <input name="file" type="file" onChange={handleSetThumbnail} />
      </div>
      <div>
        <div>영상 업로드</div>
        <input name="file" type="file" onChange={handleSetVideoFiles} />
      </div>
      {/* <div> */}
      {/*  <div>다중 영상 업로드</div> */}
      {/*  <input name="files" type="file" multiple={true} onChange={handleSetVideosFiles}/> */}
      {/* </div> */}
      <div>
        <div>영상 카테고리</div>
        <input className="fileInputCategory" type="text" datatype="category" onChange={handleInputState} />
      </div>
      <div>
        <div>영상 제목</div>
        <input className="fileInputCategory" type="text" datatype="title" onChange={handleInputState} />
      </div>

      <button onClick={onDrop}>업로드</button>
      <br />
      <br />
      <br />
      <img src={`${SERVER_BASE_URL}/api/files/image/IMG_0655.JPG`} alt="" width={200} height={140} />
      <video
        id="comwelVideo"
        width="50%"
        height="50%"
        style={{ border: '1px solid #000000', backgroundColor: '#7a7a7a' }}
        controls
        preload="metadata"
        // autoPlay
        // muted
      >
        <source src={`${SERVER_BASE_URL}/api/files/video/KakaoTalk_Video_2022-08-25-00-24-43-2022-0904-233002.mp4`} type="video/mp4" />
        {/* <source src="http://127.0.0.1:4000/api/files/images/KaaoTalk_Video_2022-08-25-00-24-43.mp4" type="video/mp4"/> */}
      </video>
    </div>
  );
};

export default FileUploadPage;
