const survey = {
  pages: [
    {
      title: '만족도 설문',
      description:
        '',
      name: '만족도 설문',
      elements: [
        {
          type: 'radiogroup',
          name: '2022년 근골 콘텐츠 관련해서 만족하시나요?',
          title: '2022년 근골 콘텐츠 관련해서 만족하시나요?',
          isRequired: true,
          requiredErrorText: '항목을 선택해주세요.',
          choices: [
            {
              value: '매우 그렇다',
              text: '매우 그렇다',
            },
            {
              value: '그렇다',
              text: '그렇다',
            },
            {
              value: '보통이다',
              text: '보통이다',
            },
            {
              value: '그렇지 않다',
              text: '그렇지 않다',
            },
            {
              value: '매우 그렇지 않다',
              text: '매우 그렇지 않다',
            },
          ],
          colCount: 2,
        },
        {
          type: 'checkbox',
          name: '이 2022년 근골 콘텐츠 중 만족하시는 콘텐츠는 무엇인가요(중복가능)?',
          title: '이 2022년 근골 콘텐츠 중 만족하시는 콘텐츠는 무엇인가요(중복가능)?',
          hideNumber: false,
          isRequired: true,
          requiredErrorText: '항목을 선택해주세요.',
          validators: [
            {
              type: 'answercount',
              text: '최대 4항목만 선택할 수 있습니다.',
              minCount: 0,
              maxCount: 4,
            },
          ],
          choices: [
            '앉아서 하는 근골격계질환 예방 운동',
            '서서 하는 근골격계질환 예방 운동',
            '일상에서 할 수 있는 근골격계질환 예방 운동',
            '스포츠 테이핑 사용법',
          ],
        },
        {
          type: 'checkbox',
          name: '향후 2023년 영상 제작이 필요한 콘텐츠를 추천해 주신다면(중복가능)?',
          title: '향후 2023년 영상 제작이 필요한 콘텐츠를 추천해 주신다면(중복가능)?',
          hideNumber: false,
          isRequired: true,
          requiredErrorText: '항목을 선택해주세요.',
          validators: [
            {
              type: 'answercount',
              text: '최대 6항목만 선택할 수 있습니다.',
              minCount: 0,
              maxCount: 6,
            },
          ],
          choices: [
            '상체, 몸통, 하체 등 통증 부위별 운동법',
            '작업 공정 및 업무별 근골격계 질환 예방 운동법',
            '목, 어깨, 팔, 팔꿈치, 손목, 손가락 등 통증 부위별 운동법',
            '거북목 증후군, 허리디스크 등 특정 증상·질환별 예방 운동법',
            '근골격계 질환 예방 폼롤러 운동법',
            '기타(체크시 아래의 기타란에 작성해주세요.)',
          ],
        },
        {
          type: 'comment',
          name: '기타',
          title: '기타',
        },
      ],

    },
  ],
  pagePrevText: '이전',
  pageNextText: '다음',
  completeText: '완료',
};

export default survey;
