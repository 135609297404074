import React, { useState, useEffect } from 'react';
import '../styles/SideNavi.scss';
import LogoText from '../icons/logo-text.png';
import { Link, useNavigate } from 'react-router-dom';
import { useSWRState } from '../fetcher/useSWRState';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { useMediaQuery } from '../hooks/useMediaQuery';

const SideNavi = () => {
  const navigate = useNavigate();
  const { data: swrState, mutate: setSwrState } = useSWRState();
  const [menuVisibleState, setMenuVisibleState] = useState(false);
  const isPageWide = useMediaQuery('(min-width: 916px)');
  const handleMenuHide = () => {
    if (!isPageWide) {
      const menuBar = (document.querySelector('.sideNaviBox') as HTMLDivElement);
      menuBar.style.display = 'none';
    }
  };

  useEffect(() => {
    const menuBar = (document.querySelector('.sideNaviBox') as HTMLDivElement);
    if (isPageWide) {
      menuBar.style.display = 'block';
    } else {
      menuBar.style.display = 'none';
    }
  }, [isPageWide]);
  const logout = () => {
    sessionStorage.clear();
    setSwrState({ ...swrState, user: null });
    handleMenuHide();
    navigate('/');
  };

  const handleGoSurvey = () => {
    const isWatchedArr = Object.keys(swrState.isWatched).map((item) => swrState.isWatched[item]);
    const isUserDidntWatchAll = isWatchedArr.includes(false);
    if (isUserDidntWatchAll) {
      alert('영상을 모두 시청한 후 설문해주세요.');
    } else {
      handleMenuHide();
      navigate('/survey');
    }
  };

  const handleMenuVisible = () => {
    const menuBar = (document.querySelector('.sideNaviBox') as HTMLDivElement);
    if (menuVisibleState) {
      menuBar.style.display = 'none'; setMenuVisibleState(false);
    } else {
      menuBar.style.display = 'block'; setMenuVisibleState(true);
    }
  };

  return (
    <div className="sideNaviContainer">
      <div className="sideNaviTop">
        <img
          className="logoText"
          width="200px"
          src={LogoText}
          alt=""
          onClick={() => {
            handleMenuHide();
            navigate('/');
          }}
        />
        <MenuRoundedIcon className="menuIcon" color="disabled" style={{ fill: '#609EAE' }} onClick={handleMenuVisible} />
      </div>

      <div className="sideNaviBox">
        <div className="sideNaviBoxTop">
          <ul>
            <li onClick={() => {
              navigate('/');
              handleMenuHide();
            }}
            >
              홈
            </li>
            <li onClick={() => {
              navigate('/lectureList');
              handleMenuHide();
            }}
            >
              강의 목록
            </li>

            {swrState?.user?.email === 'h91oon@gmail.com' && (
              <li onClick={() => {
                navigate('/fileUpload');
                handleMenuHide();
              }}
              >
                영상 업로드
              </li>
            )}

            {swrState?.user?.email ? (
              <li onClick={() => {
                navigate('/survey');
                handleMenuHide();
              }}
              >
                설문 하기
              </li>
            ) : (
              <li className="goSuvey" onClick={handleGoSurvey}>설문 하기</li>
            )}

            {swrState?.user?.email && (
              <li onClick={() => {
                navigate('/surveyResult');
                handleMenuHide();
              }}
              >
                설문 결과
              </li>
            )}
          </ul>
        </div>
        {
          swrState?.user?.email
            ? (<div className="loginBtnBox" onClick={logout}>로그아웃</div>)
            : (
              <div
                className="loginBtnBox"
                onClick={() => {
                  navigate('/login');
                  handleMenuHide();
                }}
              >
                관리자 로그인
              </div>
            )
        }
      </div>
    </div>
  );
};

export default SideNavi;
