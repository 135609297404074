import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Home.scss';
import SlideImg from '../components/SlideImg';
import useSWR from 'swr';
import { VideoType } from '../../@types/video';
import { getFetcher } from '../fetcher/fetcher';
import TypeIt from 'typeit-react';

const HomePage = () => {
  const navigate = useNavigate();
  const { data: swrVideosData, error: swrVideosDataErr } = useSWR<Array<VideoType>>('/api/video', getFetcher, { refreshInterval: 5000 });

  const typeItDestroyVer = (text) => (
    <TypeIt
      getBeforeInit={(instance) => {
        instance.type(text);
        setTimeout(() => {
          document.querySelectorAll('.ti-cursor').forEach((ele) => {
            (ele as HTMLSpanElement).style.display = 'none';
          });
        }, 3000);
        return instance;
      }}
    />
  );

  return (
    <div className="HomeContainer">
      <SlideImg />
      <div className="homeContentBox">
        <h1>
          <span className="pointFont">COMWEL</span>
          건강 스트레칭
        </h1>
        <div> </div>
        <div className="homeTextContent">
          {typeItDestroyVer('열심히 일하는')}
          <br />
          {typeItDestroyVer('우리 공단 근로자의 건강 유지 ∙ 증진을 위해')}
          <br />
          {typeItDestroyVer('일터와 가정에서 활용할 수 있는')}
          <br />
          <span className="pointFont">
            <TypeIt
              element="span"
              className="pointFont"
              getBeforeInit={(instance) => {
                instance.type('건강 스트레칭');
                return instance;
              }}
            />
          </span>
          {typeItDestroyVer(' 동영상을 제작했습니다.')}
          <br />
          {typeItDestroyVer('자주 활용하여 나와 가족의 건강을 지킵시다.')}
        </div>
        <div className="contentBtnBox">
          <button onClick={() => navigate('/lectureList')}>강의목록</button>
          <button onClick={() => navigate(`/postDetail/${swrVideosData && swrVideosData[0]._id}`)}>영상시청</button>
          <button onClick={() => navigate('/survey')}>설문하기</button>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
