import React from 'react';
import '../styles/Setting.scss';
import dummy from '../db/data.json';
import { Edit, Search } from '@material-ui/icons';

const SettingPage = () => (
  <div className="settingPageWrap">
    <div className="titleBox">
      <h2>관리자 설정 페이지</h2>
      <p>관리자 전용 페이지 입니다.</p>
    </div>
    <div className="settingPageContainer">
      <div className="searchBox">
        <div className="inputCon">
          <input type="text" />
          <span><Search /></span>
        </div>
        <button>검색</button>
      </div>
      <div className="contentBox">
        <h3>업로드 한 강의</h3>
        {dummy.post.map((data, idx) => (
          <div className="content" key={idx}>
            <span>{`${data.title.slice(0, 50)}...`}</span>
            <div className="contentTxt">
              <div className="postDate">{data.date}</div>
              <span><Edit /></span>
            </div>
          </div>
        ))}
      </div>
    </div>

  </div>
);

export default SettingPage;
