import useSWR from 'swr';

type SwrStateType = {
  user: {
    email: string;
  };
  // eslint-disable-next-line @typescript-eslint/ban-types
  isWatched: {}
};

let state: SwrStateType = {
  user: {
    email: '',
  },
  isWatched: {},
};

export const useSWRState = () => {
  const { data, mutate } = useSWR<SwrStateType>('state', () => state);

  return {
    data,
    mutate: (value: SwrStateType) => {
      state = value;
      return mutate();
    },
  };
};
