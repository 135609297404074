// eslint-disable-next-line import/no-named-as-default
import LoginApi from './LoginApi';
import SurveyApi from './SurveyApi';
import SurveyResultApi from './SurveyResultApi';
import UserApi from './UserApi';
import VideoApi from './VideoApi';

export default class Api {
  static login = new LoginApi();

  static survey = new SurveyApi();

  static surveyResult = new SurveyResultApi();

  static user = new UserApi();

  static video = new VideoApi();
}
