import React from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import * as ReactDOM from 'react-dom/client';

// export const SERVER_BASE_URL = 'https://api.comwel.net';
// export const SERVER_BASE_URL = 'http://127.0.0.1:4000';
export const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
axios.defaults.baseURL = SERVER_BASE_URL;
axios.defaults.withCredentials = false;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals();
