import React, { useEffect, useState } from 'react';
import surveyData from '../surveis/survey';
// import { Survey } from 'survey-react';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.css';
import '../styles/pages/SurveyPage.scss';
import { useNavigate } from 'react-router-dom';
import Api from '../api/Api';
import { SurveyResultType } from '../../@types/survey';
import { useSWRState } from '../fetcher/useSWRState';

StylesManager.applyTheme('defaultV2');

const SurveyPage = () => {
  const navigate = useNavigate();
  const { data: swrState, mutate: setSwrState } = useSWRState();
  const surveyModel = new Model(surveyData);
  const surveyTitles = surveyData.pages[0].elements.map((obj) => obj.title);

  const handleClick = (e) => {
    console.log('e', e.valuesHash);
    const surveyResult = e.valuesHash;
    const surveyResultObj:SurveyResultType = {
      email: 'hi',
      surveyId: '',
      result: surveyResult,
    };
    Api.surveyResult.saveData(surveyResultObj);

    setTimeout(() => {
      navigate('/lectureList');
    }, 2000);
  };

  const handleIsWatchedCheck = () => {
    const isWatchedArr = Object.keys(swrState?.isWatched).map((item) => swrState?.isWatched[item]);
    const isUserDidntWatchAll = isWatchedArr.includes(false);
    if (isUserDidntWatchAll) {
      alert('영상을 모두 시청한 후 설문해주세요.');
      navigate('/lectureList');
    }
  };

  /* INIT EFFECT */
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    /* 설문하기 page 확인용, 확인후 주석 풀것 */
    // swrState && handleIsWatchedCheck();
    const etcEle = document.querySelectorAll('.sd-row')[3] as HTMLDivElement;
    etcEle.style.display = 'none';
  }, [swrState]);

  const onSurveyChange = (e) => {
    // console.log(e.valuesHash);
    const value = e.valuesHash;
    const etcEle = document.querySelectorAll('.sd-row')[3] as HTMLDivElement;
    const isEtcChecked = value[surveyTitles[2]]?.includes('기타(체크시 아래의 기타란에 작성해주세요.)');
    isEtcChecked
      ? (etcEle.style.display = 'block')
      : (etcEle.style.display = 'none');
  };

  return (
    <div className="surveyPageContainer">
      <div className="surveyBox">
        <Survey
          model={surveyModel}
          // json={surveyDataState}
          onComplete={handleClick}
          onValueChanged={onSurveyChange}
        />
      </div>

    </div>
  );
};

export default SurveyPage;
