import React, {
  startTransition, useEffect, useMemo, useState,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import '../styles/PostDetail.scss';
import Api from '../api/Api';
import { VideoType } from '../../@types/video';
import { SERVER_BASE_URL } from '../index';
import useSWR from 'swr';
import { getFetcher, getFindFetcher } from '../fetcher/fetcher';
import dayjs from 'dayjs';
import { useSWRState } from '../fetcher/useSWRState';
import { useMediaQuery } from '../hooks/useMediaQuery';

const PostDetailPage = () => {
  const { id } = useParams();
  const { data: swrState, mutate: setSwrState } = useSWRState();
  const navigation = useNavigate();
  const { data: swrVideosData } = useSWR<Array<VideoType>>('/api/video', getFetcher, { refreshInterval: 5000 });
  const { data: swrVideoOneData } = useSWR<VideoType>(`/api/video/${id}`, getFindFetcher, { refreshInterval: 1000 });
  const [viewUpdated, setViewUpdated] = useState(false);
  const isPageWide = useMediaQuery('(min-width: 916px)');
  const handleMenuHide = () => {
    if (!isPageWide) {
      const menuBar = (document.querySelector('.sideNaviBox') as HTMLDivElement);
      menuBar.style.display = 'none';
    }
  };

  /* INIT EFFECT */
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    (document.querySelector('.layoutContainer') as HTMLDivElement).style.overflowY = 'auto';
    (document.querySelector('.layoutRightContainer') as HTMLDivElement).style.overflowY = 'visible';
    return () => {
      (document.querySelector('.layoutContainer') as HTMLDivElement).style.overflowY = 'visible';
      (document.querySelector('.layoutRightContainer') as HTMLDivElement).style.overflowY = 'auto';
    };
  }, []);

  /* 비디오 재생 추적 */
  useEffect(() => {
    let videoInterval;
    if (swrState && Object.keys(swrState.isWatched).length > 0) {
      videoInterval = setInterval(() => {
        /* 비디오 끝났는지 확인 */
        const isVideoDone = (document.querySelector('#comwelVideo') as HTMLVideoElement)?.ended;
        if (isVideoDone && !viewUpdated) {
          clearInterval(videoInterval);
          setViewUpdated(true);
          startTransition(() => {
            Api.video.modifyOneData(id, { viewCount: swrVideoOneData?.viewCount + 1 }).then(() => {
              setViewUpdated(false);
            }).finally(() => {
              setSwrState({ ...swrState, isWatched: { ...swrState.isWatched, [id]: true } });
              const isWatchedArr = Object.keys(swrState?.isWatched).filter((key) => key !== id).map((item) => ({ [item]: swrState?.isWatched[item] }));
              const nextUnwatchedVideo = isWatchedArr.find((item) => Object.values(item)[0] === false);
              if (nextUnwatchedVideo === null || nextUnwatchedVideo === undefined) {
                navigation('/survey');
                alert('영상 시청을 모두 완료하였습니다😇 설문에 참여해주세요.🥹');
                return;
              }
              const unwatchedVideoKey = Object.keys(nextUnwatchedVideo)[0];
              console.log('unwatchedVideoKey', unwatchedVideoKey);
              navigation(`/postDetail/${unwatchedVideoKey}`);
              swrVideoOneData?.title && alert(`'${swrVideoOneData?.title}' 영상 시청을 완료하였습니다.`);
            });
          });
        }
      }, 500);
    }
    return () => clearInterval(videoInterval);
  }, [swrVideoOneData, swrState]);

  const handleClickVideo = (e) => {
    const target = e.currentTarget;
    const itemID = target.getAttribute('itemID');
    navigation(`/postDetail/${itemID}`);
  };

  /* useMemo 사용시 영상 자동 시청 버그생김 */
  const videosMap = swrVideosData?.filter((data) => data._id !== id).map((data, idx) => (
    <div className="videoImageBox" onClick={handleClickVideo} itemID={data._id} key={idx}>
      <img src={`${SERVER_BASE_URL}/api/files/image/${data.thumbnail}`} alt="" />
      <div className="videoInfoBox">
        <span className="videoTitle">{data.title}</span>
        <div className="videoInfo">
          <span className="videoCreatedAt">{dayjs(data.createdAt).format('YYYY-MM-DD')}</span>
          <span className="videoViewCnt">{`${data.viewCount}.view`}</span>
        </div>
      </div>
    </div>
  ));

  const handleGoSurvey = () => {
    const isWatchedArr = Object.keys(swrState.isWatched).map((item) => swrState.isWatched[item]);
    const isUserDidntWatchAll = isWatchedArr.includes(false);
    isUserDidntWatchAll ? alert('영상을 모두 시청한 후 설문해주세요.') : navigation('/survey');
  };

  return (
    <div className="postDetailContainer" onScroll={handleMenuHide}>
      <span className="arrowIcon">
        <ArrowBackIosIcon onClick={() => navigation(-1)} />
      </span>

      <div className="titleCon">
        <div className="titleBox">
          <div className="avatar">
            <img src={`${SERVER_BASE_URL}/api/files/image/${swrVideoOneData?.thumbnail}`} alt="" />
          </div>
          <div className="txtBox">
            <div className="topTxt">
              <div className="title">{swrVideoOneData?.title}</div>
              <div className="date">{dayjs(swrVideoOneData?.createdAt).format('YYYY-MM-DD')}</div>
            </div>

            <div className="bottomTxt">
              <div className="name">{swrState?.isWatched[id] ? '✅ 시청완료' : '☑️ 미시청'}</div>
              <span className="downloadIcon">
                {/* <VisibilityIcon /> */}
                👀
                &nbsp;&nbsp;
                <span>{`${swrVideoOneData?.viewCount} .views`}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="videoCon">
        <video
          id="comwelVideo"
          width="100%"
          height="100%"
          style={{
            backgroundColor: '#000000',
            backgroundImage: `url("${SERVER_BASE_URL}/api/files/image/${swrVideoOneData?.thumbnail}")`,
          }}
          controls
          preload="metadata"
          playsInline
          muted
          draggable
          controlsList="nodownload"
          poster={`${SERVER_BASE_URL}/api/files/image/${swrVideoOneData?.thumbnail}`}
          src={swrVideoOneData?.path && `${SERVER_BASE_URL}/api/files/video/${swrVideoOneData?.path}`}
        >
          <source
            src={swrVideoOneData?.path && `${SERVER_BASE_URL}/api/files/video/${swrVideoOneData?.path}`}
            type="video/mp4"
          />
        </video>

        <div className="videosBox">{videosMap}</div>
      </div>
      <div className="txtCon">
        <div className="goSuvey" onClick={handleGoSurvey}>설문하기</div>
        <span>
          모든 영상 시청 후 설문에 응답해주세요.
          <br />
          *2023년 근골격계 질환 예방 콘텐츠 제작 시 의견 수렴 목적
        </span>

      </div>
      <div className="buttons">
        <div className="leftBox box">
          {/* 관리자 페이지에 들어가야할 button */}
          {/* <button>수정</button> */}
          {/* <button>삭제</button> */}
        </div>
        <div className="rightBox box">
          <button onClick={() => navigation('/lectureList')}>목록</button>
        </div>
      </div>
    </div>
  );
};

export default PostDetailPage;
