import React, { useState } from 'react';
import '../styles/PostForm.scss';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import parse from 'html-react-parser';

const PostForm = ({ handleForm, modalClose }) => {
  const [movieContent, setMovieContent] = useState({
    title: '',
    content: '',
  });

  const [viewContent, setViewContent] = useState([]);

  const getValue = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    setMovieContent({
      ...movieContent,
      [name]: value,
      // movieContent 의 내용을 복사해서 그 안에 name이라는 이름의 키의 값을 value로 바꿔 저장
    });
    console.log(movieContent);
  };

  const ckEditor = (
    <CKEditor
      style={{ height: '100px' }}
      editor={ClassicEditor}
      placeholder="내용을 입력해 주세요"
      onChange={(event, editor) => {
        const data = editor.getData();
        console.log({ event, editor, data });
        setMovieContent({
          ...movieContent,
          content: data,
        });
        console.log(movieContent);
      }}
    />
  );

  return (
    <div className="postFormContainer">

      {/* <div className='postFormBox'> */}
      {/*    {viewContent.map(ele=> */}
      {/*        <div className="answer"> */}
      {/*            <h2>{ele.title}</h2> */}
      {/*            <div> */}
      {/*                /!*{ele.content}*!/ */}
      {/*                {parse(ele.content)} */}
      {/*            </div> */}
      {/*        </div> */}
      {/*    )} */}
      {/* </div> */}
      <div className="formWrap">
        <div className="closeBtn" onClick={modalClose}>X</div>
        <div className="formBox">
          <div className="formInput">
            <input className="title-input" type="text" placeholder="강의명: " onChange={getValue} name="title" />
            <select name="category">
              <option value="">--선택--</option>
              <option value="business_operation">사업운영</option>
              <option value="business_development">사업개발</option>
              <option value="AI_consulting">AI 컨설팅</option>
              <option value="AI_lab">AI 연구소</option>
            </select>
          </div>
          {ckEditor}
        </div>
        <button
          className="submit-button"
          onClick={() => {
            setViewContent(viewContent.concat({ ...movieContent }));
          }}
        >
          업로드
        </button>
      </div>
    </div>
  );
};

export default PostForm;
