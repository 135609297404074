import React from 'react';
import slideImage1 from '../images/SlideImage1.webp';
import slideImage2 from '../images/SlideImage2.webp';
import slideImage3 from '../images/SlideImage3.webp';
import '../styles/SlideImg.scss';
// import prev from "../src/icons/previous.png";
// import next from "../src/icons/next.png";

const SlideImg = () => (
  <div className="slideContainer">
    <input type="radio" name="slide" id="slide01" checked />
    <input type="radio" name="slide" id="slide02" />
    <input type="radio" name="slide" id="slide03" />
    <ul className="slideList">
      <li className="slideItem">
        <a>
          <label htmlFor="slide05" className="prev" />
          <img src={slideImage1} alt="" />
          <label htmlFor="slide02" className="next" />
        </a>
      </li>
      <li className="slideItem">
        <a>
          <label htmlFor="slide01" className="prev" />
          <img src={slideImage2} alt="" />
          <label htmlFor="slide03" className="next" />
        </a>
      </li>
      <li className="slideItem">
        <a>
          <label htmlFor="slide02" className="prev" />
          <img src={slideImage3} alt="" />
          <label htmlFor="slide04" className="next" />
        </a>
      </li>
      {/* <li className="slideItem"> */}
      {/*  <a> */}
      {/*    <label htmlFor="slide04" className="prev"/> */}
      {/*    <img src={slideImage5}/> */}
      {/*    <label htmlFor="slide01" className="next"/> */}
      {/*  </a> */}
      {/* </li> */}
    </ul>
  </div>
);

export default SlideImg;
