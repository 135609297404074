import React, { useEffect, useMemo, useState } from 'react';
import '../styles/LectureList.scss';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { getFetcher } from '../fetcher/fetcher';
import { VideoType } from '../../@types/video';
import { SERVER_BASE_URL } from '../index';
import { useSWRState } from '../fetcher/useSWRState';
import dayjs from 'dayjs';
import { useMediaQuery } from '../hooks/useMediaQuery';

const LectureListPage = () => {
  const navigate = useNavigate();
  const { data: swrState, mutate: setSwrState } = useSWRState();
  const { data: swrVideoData } = useSWR<Array<VideoType>>('/api/video', getFetcher, { refreshInterval: 5000 });
  const isPageWide = useMediaQuery('(min-width: 916px)');
  const handleMenuHide = () => {
    if (!isPageWide) {
      const menuBar = (document.querySelector('.sideNaviBox') as HTMLDivElement);
      menuBar.style.display = 'none';
    }
  };

  // 메뉴 클릭시 색상 추가
  const [currentClick, setCurrentClick] = useState(null);
  const [prevClick, setPrevClick] = useState(null);

  useEffect(() => {
    if (currentClick !== null) {
      const current = document.getElementById(currentClick);
      current.style.color = '#fd920d';
      current.style.fontWeight = 'bold';
    }

    if (prevClick !== null) {
      const prev = document.getElementById(prevClick);
      prev.style.color = '#292929';
      prev.style.fontWeight = 'normal';
    }
    setPrevClick(currentClick);
  }, [currentClick]);

  const handleGoSurvey = () => {
    const isWatchedArr = Object.keys(swrState.isWatched).map((item) => swrState.isWatched[item]);
    const isUserDidntWatchAll = isWatchedArr.includes(false);
    if (isUserDidntWatchAll) {
      alert('영상을 모두 시청한 후 설문해주세요.');
    } else {
      navigate('/survey');
    }
  };

  /* INIT EFFECT */
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const videoDataListMap = useMemo(() => swrVideoData?.sort((prev, next) => {
    if (prev.sequence > next.sequence) return 1;
    if (prev.sequence < next.sequence) return -1;
    return 0;
  }).map((data, idx) => (
    <div className="lectureList" key={idx}>
      <Link to={`/postDetail/${data._id}`} key={idx}>
        <div className="lectureListImg">
          <img src={`${SERVER_BASE_URL}/api/files/image/${data.thumbnail}`} alt="" />
          {/* <img src="http://127.0.0.1:4000/api/files/image/IMG_0655.JPG" alt="" width={200} height={140}/> */}
        </div>
        <div className="lectureListTxt">
          <div className="txtBox">
            <h3 className="title">{data.title}</h3>
            <div className="desc">{data.category}</div>
            <div className="add">
              <div className="date">{dayjs(data.createdAt).format('YYYY-MM-DD')}</div>
              {/* 나중에 length로 받아와서 바꾸기 */}
              {/* <div className="commentCount">{data.viewCount}개의 댓글</div> */}
            </div>
          </div>
          <div className="writer">
            {swrState.isWatched[data._id] ? '✅ 시청완료' : '☑️ 미시청'}
          </div>
        </div>
      </Link>
    </div>
  )), [swrVideoData]);

  return (
    <div className="lectureListWrap" onScroll={handleMenuHide}>
      <div className="titleBox">
        <h2>전체 강의 목록</h2>
      </div>
      <div className="lectureListContainer" onScroll={handleMenuHide}>
        <div className="categoryCon" />
        <div className="lectureListBox">
          {videoDataListMap}
        </div>
        <div className="goSuvey" onClick={handleGoSurvey}>설문조사 하기</div>
      </div>
    </div>
  );
};

export default LectureListPage;
