import qs from 'qs';
import React, { useEffect, useState } from 'react';
import '../styles/pages/Main.scss';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

declare global {
  interface Window {
    Kakao: {
      API: {
        init: any
        request: any
      },
      init: any,
      Auth: any

    };
  }
}

// eslint-disable-next-line no-self-assign
window.Kakao = window.Kakao;

const MainPage = () => {
  const REST_API_KEY = 'bba24b6766a1138454a94e8bd75da4e0';
  // const REDIRECT_URI = "http://localhost:3000/oauth/kakao/callback";
  const REDIRECT_URI = 'http://localhost:3010';
  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;
  const CLIENT_SECRET = 'LxRb4lKyqTGXVqeGXEctA261YpgTUB8X';
  const navigate = useNavigate();
  const code = new URL(window.location.href).searchParams.get('code'); // calllback으로 받은 인가코드
  const [userId, setUserId] = useState();
  const [nickName, setNickName] = useState();
  const [profileImage, setProfileImage] = useState();

  const getProfile = async () => {
    console.log('window.Kakao', window.Kakao);
    try {
      // Kakao SDK API를 이용해 사용자 정보 획득
      const data = await window.Kakao.API.request({
        url: '/v2/user/me',
      });

      // 사용자 정보 변수에 저장
      setUserId(data.id);
      setNickName(data.properties.nickname);
      setProfileImage(data.properties.profile_image);
      localStorage.setItem('userId', data.id);
      localStorage.setItem('nickName', data.properties.nickname);
      localStorage.setItem('profileImage', data.properties.profile_image);
    } catch (err) {
      console.log(err);
    }
  };

  const getToken = async () => {
    console.log('getToken REST_API_KEY ', REST_API_KEY);
    console.log('getToken REDIRECT_URI ', REDIRECT_URI);
    console.log('getToken code ', code);
    console.log('getToken CLIENT_SECRET ', CLIENT_SECRET);
    const payload = qs.stringify({
      grant_type: 'authorization_code',
      client_id: REST_API_KEY,
      redirect_uri: REDIRECT_URI,
      code,
      client_secret: CLIENT_SECRET,
    });

    try {
      // access token 가져오기
      const res = await axios.post(
        'https://kauth.kakao.com/oauth/token',
        payload,
      );
      console.log('res.data.access_token', res.data.access_token);

      // Kakao Javascript SDK 초기화
      window.Kakao.init(REST_API_KEY);
      // access token 설정
      window.Kakao.Auth.setAccessToken(res.data.access_token);
      // navigate("/profile");
    } catch (err) {
      console.log(err);
    }
  };

  const sendKakaoTokenToServer = (token: string) => {
    console.log('token', token);
    axios.post('/auth/kakao', { access_token: token })
      .then((res) => {
        console.log('🍟');
        if (res.status === 201 || res.status === 200) {
          const { user } = res.data;
          console.log('user', user);
          localStorage.setItem('token', JSON.stringify({
            access_token: res.data.jwt,
          }));
        } else {
          window.alert('로그인에 실패하였습니다.');
        }
      });
  };

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    console.log('main');
    console.log('main code', code);
    // console.log('    window.Kakao.Auth.getAccessToken()    \n',    window?.Kakao?.Auth?.getAccessToken());

    // sendKakaoTokenToServer('JlB8l9YH1iiev26awYB3hNVlG0bBwUmFMEqCqLBhCj11GQAAAYLF50Y8')
    // p-0i8AOj2Q5OiW4xkczPuZ15gSth58hVyZ-tmntgHKw9dH2E8Wd5IN_RgKm8hcFzZXbqeAo9cxgAAAGCxjgcTA
    // a53WAkwFEtjGS6ei0tM0Hz7DO8-C9YBBcoRg0k2XCj1z7AAAAYLGOB1v
    const tokenCode = 'p-0i8AOj2Q5OiW4xkczPuZ15gSth58hVyZ-tmntgHKw9dH2E8Wd5IN_RgKm8hcFzZXbqeAo9cxgAAAGCxjgcTA';
    // const tokenCode = 'a53WAkwFEtjGS6ei0tM0Hz7DO8-C9YBBcoRg0k2XCj1z7AAAAYLGOB1v'

    axios.get(`http://localhost:5010/api/auth/kakao/callback/${code}`, {
      withCredentials: false,
    });
  }, []);

  return (
    <div className="mainContainer">
      <button onClick={() => navigate('/login')}>go login page</button>
      <br />
      <a href={KAKAO_AUTH_URL}>kakao login</a>
      <br />
      <button onClick={getProfile}>get profile</button>
      <br />
      <button
        style={{ border: '1px solid #000000', width: 'auto', height: 'auto' }}
        onClick={async () => {
          await axios.get('http://localhost:5010/api/auth/kakao/login', {
          // headers: {
          //   'Access-Control-Allow-Origin': '*',
          //   'Access-Control-Allow-Credentials': true
          // },
            withCredentials: false,
          }).then((res) => {
            console.log(res);
            console.log(res.data);
            window.location.replace(res.data);
          });
        }}
      >
        Backend 연계 테스트
        <br />
        kakao fast test login
        <br />
        이거 누르면 다음 순서로 이동함
        <br />
        1.http://localhost:5010/api/auth/kakao/login
        <br />
        2.http://localhost:5010/api/auth/kakao/callback
        <br />
      </button>
      <br />
      <div>
        <h2>
          userId:
          {/* eslint-disable-next-line camelcase */}
          {userId}
        </h2>
        <h2>
          nickName:
          {nickName}
        </h2>
        <br />
        img
        <img src={profileImage} alt="" />
      </div>
    </div>
  );
};

export default MainPage;
